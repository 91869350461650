export default [
    {
      title: 'Clientes',
      icon: 'BriefcaseIcon',
      children: [
        {
          title: 'Afiliados',
          route: 'clients',
        },
      ],
    },
  ]
  