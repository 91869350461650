export default [
    {
        title: 'Proveedores',
        route: 'providers',
        icon: 'TruckIcon',
        children: [
            {
              title: 'Afiliados',
              route: 'providers',
            },
            {
                title: 'Productos',
                route: 'products',
              },
        ],
      },
    ]
    



